.card-image {
    width: 50px;
    height: 50px;
    border-radius: 25px;
}

.card-image img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 25px;
}