#logo,
#logo img {
    width: 300px;
}

#logo {
    margin-bottom: 30px;
}

.auth-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.auth-form {
    width: 420px;
    padding-top: 30px;
    padding-bottom: 20px;
    border-radius: 8px;
    background-color: white;
}

.auth-form-content {
    padding-left: 12%;
    padding-right: 12%;
}

.auth-form-title {
    text-align: center;
    margin-bottom: 1em;
    font-size: 24px;
    color: rgb(34, 34, 34);
    font-weight: 800;
}

label {
    font-size: 16px;
    font-weight: 600;
    color: rgb(34, 34, 34);
}

.error {
    color: red;
}

.submit,
.submit:hover,
.submit:active {
    background-color: #25474e !important;
    border: 0px !important;
    color: white !important;
}